'use client';

import { Button, Input, AppleLoginButton } from '@get-mosh/msh-shared';
import React, { useEffect, useState, useCallback, FormEvent } from 'react';
import GoogleLoginButton from 'src/components/GoogleLoginButton';
import LayoutBasic from 'src/views/Layout/Basic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { validateEmail } from 'src/helpers/utils';
import Routes from 'src/constants/Routes';
import {
    useLogin,
    useLoginWithApple,
    AppleAuthenticationRequest,
} from 'src/hooks/User/useLogin';
import classNames from 'classnames';
import { isMosh, isMoshy } from 'src/env.mjs';
import Link from 'next/link';

const Login = () => {
    const { mutate: login, isPending, isSuccess } = useLogin();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(true);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        if (email === '') {
            setEmailError(false);
        } else {
            setEmailError(emailTouched && !validateEmail(email));
        }
    }, [email, emailTouched]);

    useEffect(() => {
        setContinueDisabled(!(validateEmail(email) && password));
    }, [email, password]);

    const handleSubmit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();
            if (validateEmail(email) && password) {
                login({ identity: email, password });
            } else {
                if (!validateEmail(email)) setEmailTouched(true);
                if (!password) setPasswordTouched(true);
            }
        },
        [email, password, login],
    );

    const { mutateAsync } = useLoginWithApple();
    const handleAppleLoginSuccess = (data: AppleAuthenticationRequest) => {
        mutateAsync(data);
    };

    return (
        <LayoutBasic className="flex items-center justify-center lg:min-h-screen">
            <form
                className="flex flex-col rounded-2xl border border-grey-200 bg-white p-6 shadow"
                onSubmit={handleSubmit}
            >
                <h3 className="text-left">Log in</h3>
                <div className="flex flex-col items-center gap-2 py-6">
                    <GoogleLoginButton />
                    <AppleLoginButton
                        onLoginSuccess={handleAppleLoginSuccess}
                    />
                </div>
                <div className="flex flex-row items-center gap-4 pb-6">
                    <div className="flex-1 border-t border-grey-200"></div>
                    <span className="text-sm text-grey-400">
                        or continue with email
                    </span>
                    <div className="flex-1 border-t border-grey-200"></div>
                </div>
                <div>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="john@example.com"
                        error={
                            emailError
                                ? ' Please enter a valid email address'
                                : ''
                        }
                        onFocus={() => emailTouched && setEmailTouched(false)}
                        onBlur={() => {
                            if (!emailTouched) {
                                setEmailTouched(true);
                                setEmail(email.trim());
                            }
                        }}
                        showFeedback={true}
                    />
                    <div className="relative">
                        <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Password"
                            type={passwordVisible ? 'text' : 'password'}
                            error={
                                passwordError
                                    ? 'Please enter a valid password'
                                    : ''
                            }
                            onFocus={() =>
                                passwordTouched && setPasswordTouched(false)
                            }
                            onBlur={() =>
                                !passwordTouched && setPasswordTouched(true)
                            }
                            showFeedback={false}
                        />
                        <FontAwesomeIcon
                            icon={passwordVisible ? faEyeSlash : faEye}
                            className="absolute right-3 top-5 -translate-y-1 transform cursor-pointer text-grey-300"
                            onClick={togglePasswordVisibility}
                        />
                    </div>
                </div>
                <div className="pb-4">
                    <a
                        className={classNames(
                            'text-left font-demi text-xs hover:underline',
                            {
                                'text-green-faint-dark': isMosh,
                                'text-blue-info': isMoshy,
                            },
                        )}
                        href={Routes.FORGOT_PASSWORD}
                    >
                        Forgot password?
                    </a>
                </div>
                <Button
                    type="submit"
                    disabled={continueDisabled}
                    isLoading={isPending || isSuccess}
                >
                    Continue
                </Button>
                <div className="pt-4 text-center">
                    <span className="text-xs text-grey-400">New to Mosh? </span>
                    <Link
                        className={classNames(
                            'font-demi text-xs hover:underline',
                            {
                                'text-green-faint-dark': isMosh,
                                'text-blue-info': isMoshy,
                            },
                        )}
                        href={Routes.CREATE_ACCOUNT}
                    >
                        Create an account here
                    </Link>
                </div>
            </form>
        </LayoutBasic>
    );
};
export default Login;
